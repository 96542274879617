import type { PropsWithChildren } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import { Typography } from "@noticia/ui";

type Props = {
	title: string;
	open: boolean;
	onClose?: () => void;
	showCloseButton?: boolean;
};

const BottomDrawer = (props: PropsWithChildren<Props>) => {
	const { title, open, onClose, showCloseButton = false, children } = props;

	return (
		<Drawer
			anchor="bottom"
			open={open}
			onClose={onClose}
			PaperProps={{ sx: { borderTopLeftRadius: 20, borderTopRightRadius: 20 } }}
		>
			<Box role="presentation" width="auto">
				<div>
					<Typography
						variant="body3"
						fontWeight="bold"
						component="h6"
						my={3}
						width="100%"
						textAlign="center"
					>
						{title}
					</Typography>
					{showCloseButton && onClose && (
						<Box
							p={0.25}
							top={10}
							right={10}
							position="absolute"
							display="flex"
							sx={(theme) => ({
								cursor: "pointer",
								borderRadius: 16,
								backgroundColor: theme.palette.neutral[50],
							})}
							onClick={onClose}
						>
							<CloseRoundedIcon
								sx={(theme) => ({
									fontSize: theme.typography.body3_regular.fontSize,
								})}
							/>
						</Box>
					)}
				</div>
				<Divider />
				<Box px={3} pt={1.5} pb={3}>
					{children}
				</Box>
			</Box>
		</Drawer>
	);
};

export default BottomDrawer;
