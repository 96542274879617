import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicateRounded";
import type { SxProps, Theme } from "@mui/material/styles";

type IconSizes = "xsmall" | "small" | "medium" | "large";

const iconSize: Record<IconSizes, number> = {
	xsmall: 13,
	small: 16,
	medium: 20,
	large: 35,
};

type Props = {
	size?: IconSizes | number;
	rotated?: boolean;
	color?: "primary" | "secondary";
	sx?: SxProps<Theme>;
};

const RewardIcon = (props: Props) => {
	const { size, rotated = false, color = "primary", sx = {} } = props;

	const sizeInPixels =
		typeof size === "number" ? size : iconSize[size || "medium"];

	return (
		<ControlPointDuplicateIcon
			sx={[
				(theme) => ({
					height: sizeInPixels,
					width: sizeInPixels,
					color:
						color === "primary"
							? theme.palette.warning.light
							: theme.palette.text.secondary,
					rotate: rotated ? "180deg" : "none",
				}),
				...(Array.isArray(sx) ? sx : [sx]),
			]}
		/>
	);
};

export default RewardIcon;
