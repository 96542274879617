import type { ReactElement } from "react";
import Box from "@mui/material/Box";
import type { Variant } from "@mui/material/styles/createTypography";
import Typography, {
	TypographyPropsVariantOverrides,
} from "@mui/material/Typography";

type NavigationChipSize = "small" | "medium";

const sizeToHeight: Record<NavigationChipSize, number> = {
	small: 19,
	medium: 32,
};

const sizeToLabelVariant = {
	small: "body5_medium",
	medium: "body3_medium",
};

export type ChipProps = {
	label: string;
	size?: NavigationChipSize;
	icon: ReactElement;
	selected?: boolean;
	onClick?: () => void;
};

const Chip = (props: ChipProps) => {
	const { label, size = "medium", icon, selected = false, onClick } = props;

	return (
		<Box
			display="flex"
			alignItems="center"
			height={sizeToHeight[size]}
			color={selected ? "common.white" : "primary.dark"}
			aria-label={label}
			sx={(theme) => ({
				width: "fit-content",
				cursor: "pointer",
				pr: size === "small" ? 1 : 1.5,
				borderRadius: theme.spacing(2),
				backgroundColor: selected ? "primary.dark" : theme.palette.neutral[50],
			})}
			onClick={onClick}
		>
			{icon}
			<Typography
				variant={
					sizeToLabelVariant[size] as unknown as Variant &
						TypographyPropsVariantOverrides
				}
				lineHeight="normal"
				component="span"
			>
				{label}
			</Typography>
		</Box>
	);
};

export default Chip;
